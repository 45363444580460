<template>
  <div class="pageContol listWrap templateList addlectuer">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">讲师列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{ title }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="addlist">
              <div>
                <h3>
                  <span>基本信息</span>
                </h3>
                <div class="form-box">
                  <el-form
                    ref="baseform"
                    label-width="7rem"
                    class="form"
                    :rules="rules"
                    :model="baseform"
                    ><el-form-item label="讲师姓名" prop="teacherName">
                      <el-input
                        v-model="baseform.teacherName"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="teacherIdcard">
                      <el-input v-model="baseform.teacherIdcard"></el-input>
                      <div @click="showInfo" v-show="showtip" class="tip">
                        {{ idcard || "" }}
                      </div>
                    </el-form-item>
                    <el-form-item label="手机号" prop="teacherPhoneNumber">
                      <el-input
                        v-model="baseform.teacherPhoneNumber"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="使用状态" prop="teacherEnable">
                      <el-select
                        v-model="baseform.teacherEnable"
                        placeholder="请选择"
                      >
                        <el-option label="启用" :value="true"></el-option>
                        <el-option label="禁用" :value="false"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="身份证照片" required>
                      <el-form-item prop="sfzZjy" class="tp"
                        ><el-upload
                          :on-change="upSfzZ"
                          :before-upload="beforeAvatarUpload2"
                          :http-request="$requestMine"
                          :show-file-list="false"
                          class="img-el-upload"
                          action
                          accept="image/png, image/gif, image/jpg, image/jpeg"
                          ><el-image
                            v-if="!baseform.idCardFront"
                            :src="require('@/assets/morenimg/zimg1.png')"
                            fit="contain"
                            class="imgCenter imgCenter1"
                          ></el-image>
                          <el-image
                            v-else
                            :src="baseform.idCardFront"
                            fit="contain"
                            class="imgCenter"
                          ></el-image>
                        </el-upload>
                        <div class="tswz">
                          <p class="t1">身份证正面</p>
                          <p>图片大小不超过5M</p>
                          <p>图片分辨率不低于72dpi</p>
                          <el-popover placement="right" trigger="hover">
                            <img src="@/assets/morenimg/zimg2.png" alt="" />
                            <el-button type="text" slot="reference"
                              >查看示例</el-button
                            >
                          </el-popover>
                        </div>
                      </el-form-item>
                      <el-form-item prop="sfzFjy" class="tp"
                        ><el-upload
                          :on-change="upSfzF"
                          :before-upload="beforeAvatarUpload2"
                          :http-request="$requestMine"
                          :show-file-list="false"
                          class="img-el-upload"
                          action
                          accept="image/png, image/gif, image/jpg, image/jpeg"
                          ><el-image
                            v-if="!baseform.idCardBack"
                            :src="
                              baseform.idCardBack ||
                              require('@/assets/morenimg/zimg1.png')
                            "
                            fit="contain"
                            class="imgCenter imgCenter1"
                          ></el-image>
                          <el-image
                            v-else
                            :src="baseform.idCardBack"
                            fit="contain"
                            class="imgCenter"
                          ></el-image>
                        </el-upload>
                        <div class="tswz">
                          <p class="t1">身份证反面</p>
                          <p>图片大小不超过5M</p>
                          <p>图片分辨率不低于72dpi</p>
                          <el-popover placement="right" trigger="hover">
                            <img src="@/assets/morenimg/zimg3.png" alt="" />
                            <el-button type="text" slot="reference"
                              >查看示例</el-button
                            >
                          </el-popover>
                        </div>
                      </el-form-item>
                    </el-form-item>
                    <el-form-item label="毕业证书">
                      <el-form-item prop="byzsjy" class="tp"
                        ><el-upload
                          :on-change="upbyzs"
                          :before-upload="beforeAvatarUpload2"
                          :http-request="$requestMine"
                          :show-file-list="false"
                          class="img-el-upload"
                          action
                          accept="image/png, image/gif, image/jpg, image/jpeg"
                          ><el-image
                            v-if="!baseform.graduationCertificate"
                            :src="require('@/assets/morenimg/zimg1.png')"
                            fit="contain"
                            class="imgCenter imgCenter1"
                          ></el-image>
                          <el-image
                            v-else
                            :src="baseform.graduationCertificate"
                            fit="contain"
                            class="imgCenter"
                          ></el-image>
                        </el-upload>
                        <div class="tswz">
                          <p class="t1">毕业证书</p>
                          <p>图片大小不超过5M</p>
                          <p>图片分辨率不低于72dpi</p>
                          <el-popover placement="right" trigger="hover">
                            <img src="@/assets/morenimg/zimg4.png" alt="" />
                            <el-button type="text" slot="reference"
                              >查看示例</el-button
                            >
                          </el-popover>
                        </div>
                      </el-form-item>
                      <el-form-item class="tp"
                        ><el-upload
                          :on-change="upxwz"
                          :before-upload="beforeAvatarUpload2"
                          :http-request="$requestMine"
                          :show-file-list="false"
                          class="img-el-upload"
                          action
                          accept="image/png, image/gif, image/jpg, image/jpeg"
                          ><el-image
                            v-if="!baseform.degreeCertificate"
                            :src="require('@/assets/morenimg/zimg1.png')"
                            fit="contain"
                            class="imgCenter imgCenter1"
                          ></el-image>
                          <el-image
                            v-else
                            :src="baseform.degreeCertificate"
                            fit="contain"
                            class="imgCenter"
                          ></el-image>
                        </el-upload>
                        <div class="tswz">
                          <p class="t1">学位证(非必填)</p>
                          <p>图片大小不超过5M</p>
                          <p>图片分辨率不低于72dpi</p>
                          <el-popover placement="right" trigger="hover">
                            <img src="@/assets/morenimg/zimg5.png" alt="" />
                            <el-button type="text" slot="reference"
                              >查看示例</el-button
                            >
                          </el-popover>
                        </div>
                      </el-form-item>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <div class="certificate-box">
                <h3>
                  <span>证书信息</span>
                </h3>
                <div
                  class="df card-border form-box"
                  v-for="(item, index) in addlist"
                  :key="index"
                >
                  <el-form
                    :model="item"
                    ref="cardform"
                    label-width="7rem"
                    class="form form-bg"
                  >
                    <el-form-item
                      label="资质类型"
                      prop="qualificationTypeId"
                      :rules="[
                        {
                          required: true,
                          trigger: ['blur', 'change'],
                          message: '请选择资质类型',
                        },
                      ]"
                    >
                      <el-select
                        v-model="item.qualificationTypeId"
                        placeholder="请选择"
                        @change="
                          (val) => {
                            changeQualification(val, index);
                          }
                        "
                      >
                        <el-option
                          v-for="el in Qualification"
                          :key="el.qualificationTypeId"
                          :label="el.name"
                          :value="el.qualificationTypeId"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="职业证书编号"
                      prop="certificateNo"
                      :rules="[
                        {
                          required: true,
                          trigger: 'blur',
                          message: '请输入证书编号',
                        },
                      ]"
                    >
                      <el-input v-model="item.certificateNo"></el-input>
                    </el-form-item>
                    <el-form-item
                      label="是否永久有效"
                      prop="qualificationIsForever"
                      :rules="[
                        {
                          required: true,
                          trigger: ['blur', 'change'],
                          message: '请选择资质类型',
                        },
                      ]"
                    >
                      <el-select
                        v-model="item.qualificationIsForever"
                        placeholder="请选择"
                      >
                        <el-option label="是" :value="true"></el-option>
                        <el-option label="否" :value="false"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="!item.qualificationIsForever"
                      label="证书有效期"
                      prop="date"
                      :rules="[
                        {
                          required: true,
                          validator: $validateTime,
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-date-picker
                        v-model="item.date"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="timestamp"
                        @change="
                          (val) => {
                            changeTime(val, index);
                          }
                        "
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item
                      v-else
                      label="发证日期"
                      prop="qualificationStartDateMillis"
                      :rules="[
                        {
                          required: true,
                          message: '请选择日期',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <!-- {{item.qualificationStartDateMillis}} -->
                      <el-date-picker
                        v-model="item.qualificationStartDateMillis"
                        type="date"
                        value-format="timestamp"
                        @change="
                          (val) => {
                            changeTime1(val, index);
                          }
                        "
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item
                      label="证书图片"
                    >
                    <el-upload
                        action
                        list-type="picture-card"
                        :auto-upload="false"
                        :on-change="
                          (file, fileList) => {
                            handleAvatarSuccess2(file, fileList, index);
                          }
                        "
                        :file-list="item.certPhotoDTOS"
                        :before-upload="beforeAvatarUpload2"
                        :http-request="$requestMine"
                        accept="image/png, image/gif, image/jpg, image/jpeg"
                      >
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{ file }">
                          <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                          />
                          <span class="el-upload-list__item-actions">
                            <span
                              class="el-upload-list__item-preview"
                              @click="(file) => {
                                handlePictureCardPreview(file, index);
                              }"
                            >
                              <i class="el-icon-zoom-in"></i> 
                            </span>
                            <span
                              class="el-upload-list__item-delete"
                              @click="handleRemove(file,index)"
                            >
                              <i class="el-icon-delete"></i>
                            </span>
                          </span>
                        </div>
                      </el-upload>
                    </el-form-item>
                    <el-form-item label="使用状态" prop="qualificationEnable">
                      <el-switch
                        v-model="item.qualificationEnable"
                        :active="true"
                        :inactive-color="false"
                      >
                      </el-switch>
                    </el-form-item>
                    <el-form-item label="上传机构" prop="compName">
                      <el-input
                        v-model="item.compName"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <div class="df zsBtn">
                    <el-button
                      v-if="addlist.length > 1"
                      @click="delCertificate(index)"
                      type="primary"
                      round
                      class="bgc-bv"
                      >删除</el-button
                    >
                    <el-button
                      v-if="index == addlist.length - 1 && index != 9"
                      @click="certificate"
                      type="primary"
                      round
                      class="bgc-bv"
                      >添加证书</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="btn-box">
                <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
                <el-button class="bgc-bv" @click="doEditSave">确 定</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "addLecturer",
  components: {},
  data() {
    // 上传身份证正面校验
    let sfzZ = (rule, value, callback) => {
      if (!this.baseform.idCardFront) {
        callback(new Error("请上传身份证正面照片!"));
      } else {
        callback();
      }
    };
    // 上传身份证反面校验
    let sfzF = (rule, value, callback) => {
      if (!this.baseform.idCardBack) {
        callback(new Error("请上传身份证反面照片!"));
      } else {
        callback();
      }
    };
    // // 上传毕业证书
    // let byzs = (rule, value, callback) => {
    //   if (!this.baseform.graduationCertificate) {
    //     callback(new Error("请上传毕业证书!"));
    //   } else {
    //     callback();
    //   }
    // };
    // 手机号校验
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback();
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    return {
      showtip: false,
      showMoHuImg: false, // 有系统身份证 - 身份证毕业证书显示模糊
      idcard: "",
      idcardObj: {},
      stu: "add",
      title: "新增讲师",
      teacherQualifications: [],
      addlist: [], // 新增列表
      addobj: {
        certificateNo: "", //职业证书编号
        qualificationEndDateMillis: "", // 资质有效期-结束日期，毫秒
        qualificationStartDateMillis: "", // 资质有效期-起始日期，毫秒
        qualificationTypeId: "",
        date: "",
        qualificationIsForever: false, // 是否永久有效
        qualificationEnable: true,
        canDeleted: true,
      },
      Qualification: [], //资质类型,
      // 基本信息
      baseform: {
        teacherIdcard: "",
        teacherName: "",
        teacherPhoneNumber: "",
        teacherEnable: "",
        // sfzZYl:"", // 身份证正面 - 预览url
        // sfzZUrl:"", // 身份证正面 - 给后台url
        // sfzFYl:"", // 身份证反面 - 预览url
        // sfzFUrl:"", // 身份证反面 - 给后台url
        // byzsZYl:"", // 毕业证书 - 预览url
        // byzsZUrl:"", // 毕业证书 - 给后台url
        // byzFZYl:"", // 学位证 - 预览url
        // byzFZUrl:"", // 学位证 - 给后台url
        idCardFront: "",
        idCardFrontKey: "",
        idCardBack: "",
        idCardBackKey: "",
        graduationCertificate: "",
        graduationCertificateKey: "",
        degreeCertificate: "",
        degreeCertificateKey: "",
      },
      rules: {
        teacherName: [
          { required: true, trigger: "blur", message: "请输入姓名" },
        ],
        teacherIdcard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        teacherPhoneNumber: [
          {
            required: false,
            validator: Phone,
            trigger: "blur",
          },
        ],
        teacherEnable: [
          {
            required: true,
            message: "请选择使用状态",
            trigger: "change",
          },
        ],
        sfzZjy: [{ required: true, validator: sfzZ, trigger: "change" }],
        sfzFjy: [{ required: true, validator: sfzF, trigger: "change" }],
      },
      delIds: [],
      id: "",
    };
  },
  created() {
    this.getQualification();
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.stu = this.$route.query.stu;
      if (this.$route.query.stu == "add") {
        this.title = "新增讲师";
        this.addlist.push({ ...this.addobj });
      } else {
        this.title = "编辑讲师";
        this.teacherId = this.$route.query.id;
        this.queryData();
      }
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.$post("/biz/teacher/queryByTeacherCardId", {
          teacherIdcard: this.baseform.teacherIdcard,
        })
          .then((res) => {
            if (!res.data.createId) {
              this.showtip = false;
              this.baseform.sfzZYl = "";
              this.baseform.sfzFYl = "";
              this.baseform.byzsZYl = "";
              this.baseform.byzFZUrl = "";
              this.showMoHuImg = false;
            } else {
              this.idcardObj = res.data;
              this.idcard =
                res.data.teacherName +
                "/" +
                res.data.teacherIdcard +
                (res.data.teacherPhoneNumber
                  ? "/" + res.data.teacherPhoneNumber
                  : "");
              this.showtip = true;
              this.baseform.idCardFront = require("@/assets/morenimg/zimg6.png"); // 身份证正面 - 模糊url
              this.baseform.idCardBack = require("@/assets/morenimg/zimg7.png"); // 身份证反面 - 模糊url
              this.baseform.graduationCertificate = require("@/assets/morenimg/zimg8.png"); // 毕业证 - 模糊url
              this.baseform.degreeCertificate = require("@/assets/morenimg/zimg9.png"); // 学位证 - 模糊url
              this.showMoHuImg = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.showtip = false;
        // this.$refs.baseform.resetFields();
      }
    },
    showInfo() {
      this.showtip = false;
      this.baseform.teacherPhoneNumber = this.idcardObj.teacherPhoneNumber;
      this.baseform.teacherIdcard = this.idcardObj.teacherIdcard;
      this.baseform.teacherName = this.idcardObj.teacherName;
      if (this.idcardObj.teacherQualifications.length > 0) {
        this.id = this.idcardObj.teacherId;
        this.open1();
      }
    },
    // 查询资质了类型
    getQualification() {
      this.$post("/biz/qualificationType/listAll")
        .then((res) => {
          const resData = res.data || [];
          this.Qualification = resData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择资质
    changeQualification(val, ind) {
      this.addlist[ind].qualificationTypeId = val;
    },
    // 时间
    changeTime(val, index) {
      this.addlist[index].qualificationStartDateMillis = val[0];
      this.addlist[index].qualificationEndDateMillis = val[1];
    },
    changeTime1(val, index) {
      this.addlist[index].qualificationStartDateMillis = val;
      // this.addlist[index].qualificationEndDateMillis = val[1];
    },
    /// 新增 -- 证书
    certificate() {
      if (this.addlist.length == 10) {
        return;
      } else {
        this.addlist.push(
          { 
            ...this.addobj,
            certPhotoDTOS:[],
          }
        );
      }
    },
    delCertificate(ind) {
      if (this.addlist[ind].teacherId) {
        this.delIds.push({
          teacherQualificationId: this.addlist[ind].teacherQualificationId,
        });
      }
      this.addlist.splice(ind, 1);
    },
    // 上传证书照片
    handleAvatarSuccess2(file, fileList, index) {
      const fileType = file.raw.name.slice(
        file.raw.name.lastIndexOf(".") + 1,
        file.raw.name.length
      );
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      this.$Postformat("/sys/upload/watermark", formData).then((res) => {
        this.addlist[index].certPhotoDTOS.push({
          photoKey: res.data.fileKey || "", // 证书图片key
          originalPhotoKey: res.data.fileKeyNoWatermark || "", // 无水印证书图片key
          url: res.data.fileURL,
        });
      });
    },
    // 上传证书照片 - 预览
    handlePictureCardPreview(file,index) {
      console.log(file,index);
    },
    // 上传证书照片 - 删除
    handleRemove(file,index) {
      for (let i = 0; i < this.addlist[index].certPhotoDTOS.length; i++) {
        if(this.addlist[index].certPhotoDTOS[i].photoKey == file.photoKey){
          this.addlist[index].certPhotoDTOS.splice(i, 1);
        }
      }
    },
    check() {
      const teachers = {};
      let stu = true;
      this.addlist.forEach((item, index) => {
        if (!teachers[item.certificateNo]) {
          teachers[item.certificateNo] = [];
        }
        teachers[item.certificateNo].push(index);
      });
      for (const key in teachers) {
        const element = teachers[key];
        element.forEach(() => {
          if (element.length > 1) {
            stu = false;
          }
        });
      }
      if (!stu) {
        this.$message({
          type: "warning",
          message: "证书号不能重复",
        });
      }
      return stu;
    },
    check1() {
      const cardform = this.$refs["cardform"];
      let stu = true;
      for (let i = 0; i < cardform.length; i++) {
        cardform[i].validate((valid1) => {
          if (valid1 == false) {
            stu = false;
          }
        });
      }
      return stu;
    },
    // 编辑保存
    doEditSave() {
      const addArr = [];
      const editArr = [];
      for (let i = 0; i < this.addlist.length; i++) {
        if (!this.addlist[i].teacherId) {
          addArr.push(this.addlist[i]);
        } else {
          editArr.push(this.addlist[i]);
        }
      }
      const params = {
        teacherId: this.teacherId,
        teacherName: this.baseform.teacherName,
        teacherPhoneNumber: this.baseform.teacherPhoneNumber,
        teacherIdcard: this.baseform.teacherIdcard,
        teacherEnable: this.baseform.teacherEnable,
        enable: this.baseform.enable,
        modifyTeacherQualifications: editArr,
        deleteTeacherQualifications: this.delIds,
        addTeacherQualifications: addArr,
        idCardFront: this.baseform.idCardFrontKey,
        idCardFrontNoWatermark: this.baseform.idCardFrontNoWatermark,
        idCardBack: this.baseform.idCardBackKey,
        idCardBackNoWatermark: this.baseform.idCardBackNoWatermark,
      };
      if (this.baseform.graduationCertificate) {
        params.graduationCertificate = this.baseform.graduationCertificateKey;
        params.graduationCertificateNoWatermark =
          this.baseform.graduationCertificateNoWatermark;
      }
      if (this.baseform.degreeCertificateKey) {
        params.degreeCertificate = this.baseform.degreeCertificateKey;
        params.degreeCertificateNoWatermark =
          this.baseform.degreeCertificateNoWatermark;
      }
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          if (this.check1()) {
            if (this.check()) {
              this.$post("/biz/teacher/update", params)
                .then((res) => {
                  if (res.status == 0) {
                    this.$router.back();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        }
      });
    },
    // 编辑查询
    queryData() {
      this.$post("/biz/teacher/querySingleTeacher", {
        teacherId: this.teacherId,
      })
        .then((res) => {
          const data = res.data || {};
          this.baseform = {
            ...this.baseform,
            teacherName: data.teacherName,
            teacherIdcard: data.teacherIdcard,
            teacherPhoneNumber: data.teacherPhoneNumber,
            teacherEnable: data.teacherEnable,
            idCardFront: data.idCardFrontUrl,
            idCardFrontKey: data.idCardFront,
            idCardBack: data.idCardBackUrl,
            idCardBackKey: data.idCardBack,
            graduationCertificate: data.graduationCertificateUrl,
            graduationCertificateKey: data.graduationCertificate,
            degreeCertificate: data.degreeCertificateUrl,
            degreeCertificateKey: data.degreeCertificate,
          };
          const teacherQualifications = data.teacherQualifications;
          teacherQualifications.map((item) => {
            item.date = [
              item.qualificationStartDateMillis,
              item.qualificationEndDateMillis,
            ];
            item.certPhotoBOS.map((item1) => {
              item1.url = item1.photoeUrl;
            });
            item.certPhotoDTOS = item.certPhotoBOS;
            return item;
          });
          this.addlist = teacherQualifications;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doCancel() {
      this.$router.push("/web/lecturerYw/list");
    },
    open1() {
      this.$notify({
        customClass: "notify",
        duration: 3000,
        dangerouslyUseHTMLString: true,
        message: `<span>该讲师已经添加到机构，是否立即对他<span style="color:#409eff;cursor: pointer;">编辑</span>？</span>`,
        position: "top-left",
        onClick: this.doEdit,
      });
    },
    doEdit() {
      this.stu = "edit";
      this.teacherId = this.id;
      this.title = "编辑讲师";
      this.$router.replace({
        path: "/web/addLecturer",
        query: { id: this.id, stu: "edit" },
      });
      this.queryData();
    },
    // 上传身份证正面
    upSfzZ(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.idCardFront = result.data.fileURL || "";
            this.baseform.idCardFrontKey = result.data.fileKey || "";
            this.baseform.idCardFrontNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传身份证反面
    upSfzF(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.idCardBack = result.data.fileURL || "";
            this.baseform.idCardBackKey = result.data.fileKey || "";
            this.baseform.idCardBackNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传毕业证书
    upbyzs(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.graduationCertificate = result.data.fileURL || "";
            this.baseform.graduationCertificateKey = result.data.fileKey || "";
            this.baseform.graduationCertificateNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 上传学位证
    upxwz(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload/watermark", formData)
          .then((result) => {
            this.baseform.degreeCertificate = result.data.fileURL || "";
            this.baseform.degreeCertificateKey = result.data.fileKey || "";
            this.baseform.degreeCertificateNoWatermark =
              result.data.fileKeyNoWatermark || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less">
.addlectuer {
  .addlist {
    h3 {
      display: flex;
      border-left: 3px solid rgb(92, 107, 232);
      padding: 0;
      span {
        margin-left: 20px;
      }
      margin-bottom: 30px;
    }
    h3::before {
      content: "";
      border: 0;
    }
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 1000px;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 1000px;
  button {
    padding: 12px 30px;
  }
}
.tp {
  width: 50%;
  float: left;
  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }
  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;
    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}
.tip {
  position: relative;
  z-index: 100;
  // width: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
}
.notify.el-notification.el-notification.left {
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  .el-notification__closeBtn {
    top: 10px;
  }
}
.imgCenter1 {
  img {
    object-fit: none !important;
  }
}
</style>
